import { Box, Container, MantineProvider } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'

import Footer from '@/containers/navigation/Footer'
import Header from '@/containers/navigation/Header'
import { useFetch } from '@/lib/hooks'
import { useAuth } from '@/providers/AuthProvider'
import {
  Channel as ChannelType,
  Showcase as ShowcaseType,
  Site as SiteType,
  Store as StoreType
} from '@/types'

import { ChannelNav, StudentNav } from './components'

interface Data {
  site?: SiteType
  channel?: ChannelType
  store?: StoreType
  showcase?: ShowcaseType
}

interface Props {
  initialData?: Data
  children?: React.ReactNode
  displayCover?: boolean
}

export default function MainLayout({ initialData, children, displayCover }: Props) {
  // Hooks
  const router = useRouter()
  const { isAuthenticated } = useAuth() || {}
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  // States
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  // Constants
  const { site: siteSlug, channel: channelSlug, conciergeCode } = router.query || {}

  // Fetch
  const { data: siteData } = useFetch([siteSlug ? `/${siteSlug}/` : null], {
    fallbackData: initialData?.site
  })

  const { data: channelData } = useFetch(
    [siteSlug && channelSlug ? `/${siteSlug}/channels/${channelSlug}/` : null],
    { fallbackData: initialData?.channel }
  )

  // Custom theme
  const customColor = siteData?.theme?.configuration?.customColor
  const customTheme: object | undefined =
    Array.isArray(customColor) && customColor.length === 10
      ? { colors: { custom: customColor }, primaryColor: 'custom' }
      : undefined

  // Constants
  const showChannelNav = channelSlug && channelData
  const showStudentNav =
    isAuthenticated &&
    !conciergeCode &&
    siteData?.beneficiary !== 'shopper' &&
    channelSlug &&
    !channelData?.maintenance
  const containerStyles = {
    position: 'relative',
    margin: 0,
    padding: 0,
    minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`
  } as const

  if (displayCover) {
    let coverImage = null
    if (channelData && channelData.cover) {
      coverImage = channelData.cover
    } else if (siteData && siteData.cover) {
      coverImage = siteData.cover
    }

    if (coverImage) {
      Object.assign(containerStyles, {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.55)), url("${coverImage}")`,
        backgroundPosition: 'center 66px',
        backgroundAttachment: 'fixed'
      })
    }
  }

  // Effects
  useEffect(() => {
    setHeaderHeight(headerRef?.current?.clientHeight || 0)
    setFooterHeight(footerRef?.current?.clientHeight || 0)
  }, [showStudentNav])

  return (
    <MantineProvider theme={customTheme} inherit>
      <Box
        sx={{ position: 'sticky', top: 0, left: 0, width: '100%', height: 'auto', zIndex: 101 }}
        ref={headerRef}>
        <Header siteData={siteData} channelData={channelData} />
        {showChannelNav && <ChannelNav initialData={initialData} />}
        {showStudentNav && <StudentNav siteData={siteData} channelData={channelData} />}
      </Box>
      <Container fluid p={0} m={0} sx={containerStyles}>
        {children}
      </Container>
      <Footer siteData={siteData} footerRef={footerRef} />
    </MantineProvider>
  )
}
